.form-label {margin-bottom: 0px;display: inherit;text-align: left;}
.form-row {margin-bottom: 15px;}
.btn-signup {
    color: #fff;
    background-color: #44a34c;
    border-color: #44a34c;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    width: 100%;
    padding: 9px 12px;
}
.auth-row{margin: 30px 0;}
.form-control.has-error {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}
.btn-signup-loading {width: 100%;padding: 9px 12px ;}