.check_container {
    display: inline-block;
    position: relative;
    margin: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 400;
}
.check-label{margin-left: 10px;display: inline-block;}
.check_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.check_container .checkedmark {
    height: 20px;
    width: 20px;
    background-color: #fff;
    display: inline-block;
    vertical-align: sub;
	border: 1px solid #00d1ff;
}
.check_container input:disabled ~ .checkedmark {
    background-color: #eee;
}
.check_container input:checked ~ .checkedmark {
    background-color: #00d1ff;
}
.check_container .checkedmark:after {
    content: "";
    position: absolute;
    display: none;
}
.check_container input:checked ~ .checkedmark:after {
    display: block;
}
.check_container .checkedmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 11px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}