@charset "ISO-8859-1";
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500,600&display=swap');

html {
	height: 100%;
}

body {
	font-family: 'Fira Sans', sans-serif;
}

/*=======================================
=            commoan css tag            =
=======================================*/
body {
	font-size: 14px;
	font-weight: 400;
	line-height: 25px;
	color: rgba(0, 0, 0, 0.8);
	-webkit-font-smoothing: antialiased;
	background-color: rgba(0, 120, 200, 0.05);
	min-height: 100%;
	overflow-x: hidden;
	overflow-y: auto
}

input[type="text"], input[type="search"], input[type="email"], input[type="password"], input.form-control {
	height: 35px;
	width: 100%;
	border: solid 2px rgba(0, 120, 200, 0.2);
	padding: 5px 10px;
	color: rgba(0, 0, 0, 0.8);
	font-weight: 400;
	font-size: 14px;
	transition: all 0.3s ease-in-out;
	border-radius: 3px
}

@media (prefers-reduced-motion:reduce) {
	input[type="text"], input[type="search"], input[type="email"], input[type="password"], input.form-control {
		transition: none
	}

}

input[type="text"]:-moz-placeholder, input[type="email"]:-moz-placeholder, input[type="password"]:-moz-placeholder, input.form-control:-moz-placeholder {
	color: rgba(0, 0, 0, 0.6)
}

input[type="text"]::-moz-placeholder, input[type="email"]::-moz-placeholder, input[type="password"]::-moz-placeholder, input.form-control::-moz-placeholder {
	color: rgba(0, 0, 0, 0.6)
}

input[type="text"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder, input.form-control:-ms-input-placeholder {
	color: rgba(0, 0, 0, 0.6)
}

input[type="text"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder, input.form-control::-webkit-input-placeholder {
	color: rgba(0, 0, 0, 0.6)
}

@media (max-width:1024px) {
	input[type="text"], input[type="search"], input[type="email"], input[type="password"], input.form-control {
		min-width: 110px
	}

}

@media (max-width:599px) {
	input[type="text"], input[type="search"], input[type="email"], input[type="password"], input.form-control {
		height: 40px;
		margin-bottom: 0
	}

}

input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, input.form-control:focus {
	border: solid 2px #0078c8;
	outline: 0 !important;
	box-shadow: 0 2px 10px -1px rgba(0, 120, 200, 0.25) !important
}

input[type="text"]:not(.error)+span+label, input[type="email"]:not(.error)+span+label, input[type="password"]:not(.error)+span+label, input.form-control:not(.error)+span+label {
	font-size: 0
}

input[type="text"]:-moz-placeholder, input[type="email"]:-moz-placeholder, input[type="password"]:-moz-placeholder, input.form-control:-moz-placeholder {
	color: #0078c8
}

input[type="text"]::-moz-placeholder, input[type="email"]::-moz-placeholder, input[type="password"]::-moz-placeholder, input.form-control::-moz-placeholder {
	color: #0078c8
}

input[type="text"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder, input.form-control:-ms-input-placeholder {
	color: #0078c8
}

input[type="text"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder, input.form-control::-webkit-input-placeholder {
	color: #0078c8
}

input[type="text"]:disabled, input[type="text"][readonly], input[type="email"]:disabled, input[type="email"][readonly], input[type="password"]:disabled, input[type="password"][readonly], input.form-control:disabled, input.form-control[readonly] {
	cursor: no-drop
}

input.form-control-lg {
	height: calc(1.5em + 1rem + 2px);
	padding: .5rem 1rem;
	font-size: 1.25rem
}

textarea, textarea.form-control {
	overflow: auto;
	border: solid 2px rgba(0, 120, 200, 0.2);
	padding: 5px 10px;
	color: rgba(0, 0, 0, 0.8);
	font-weight: 400;
	font-size: 14px;
	margin: 0 0 11px 0;
	width: 100%;
	transition: all 0.3s ease-in-out;
	border-radius: 3px
}

@media (prefers-reduced-motion:reduce) {
	textarea, textarea.form-control {
		transition: none
	}

}

textarea:-moz-placeholder, textarea.form-control:-moz-placeholder {
	color: rgba(0, 0, 0, 0.6)
}

textarea::-moz-placeholder, textarea.form-control::-moz-placeholder {
	color: rgba(0, 0, 0, 0.6)
}

textarea:-ms-input-placeholder, textarea.form-control:-ms-input-placeholder {
	color: rgba(0, 0, 0, 0.6)
}

textarea::-webkit-input-placeholder, textarea.form-control::-webkit-input-placeholder {
	color: rgba(0, 0, 0, 0.6)
}

@media (max-width:599px) {
	textarea, textarea.form-control {
		padding: 10px
	}

}

textarea:focus, textarea.form-control:focus {
	border: solid 2px #0078c8;
	outline: 0 !important
}

textarea:-moz-placeholder, textarea.form-control:-moz-placeholder {
	color: #0078c8
}

textarea::-moz-placeholder, textarea.form-control::-moz-placeholder {
	color: #0078c8
}

textarea:-ms-input-placeholder, textarea.form-control:-ms-input-placeholder {
	color: #0078c8
}

textarea::-webkit-input-placeholder, textarea.form-control::-webkit-input-placeholder {
	color: #0078c8
}

button, [type="button"], [type="reset"], [type="submit"] {
	-webkit-appearance: initial !important
}

h1, h2, h3, h4, h5, h6 {
	margin: 0 0 20px;
	color: #0078c8;
	line-height: 1.2;
	font-weight: 400
}

h1.grey, h2.grey, h3.grey, h4.grey, h5.grey, h6.grey {
	color: rgba(0, 0, 0, 0.8)
}

h1, h2, h3, h4 {
	font-weight: 400
}

h1 {
	font-size: 52px
}

@media (max-width:767px) {
	h1 {
		font-size: 32px
	}

}

h2 {
	font-size: 48px
}

@media (max-width:767px) {
	h2 {
		font-size: 27px
	}

}

h3 {
	font-size: 32px
}

@media (max-width:991px) {
	h3 {
		font-size: 27px
	}

}

h4 {
	font-size: 30px
}

@media (max-width:991px) {
	h4 {
		font-size: 25px
	}

}

h5 {
	font-size: 20px;
	line-height: 1.5
}

h6 {
	font-size: 18px;
	line-height: 28px;
	margin-bottom: 10px
}

/*=====  End of commoan css tag  ======*/

/*=============================================
=            commoan css for class            =
=============================================*/



hr {
	margin: 45px 0 43px 0;
	border-top: 1px solid rgba(0, 120, 200, 0.2)
}

@media (max-width:767px) {
	hr {
		margin: 35px 0
	}

}

hr.hr_sm {
	margin: 5px 0 5px 0
}

hr.hr_md {
	margin: 15px 0 15px 0
}

hr.hr_lg {
	margin: 30px 0 30px 0
}

@media (max-width:1366px) {
	.row {
		/*margin-left: -5px;
		margin-right: -5px*/
	}

}

@media (max-width:1366px) {
	.row [class*="col"] {
		/*padding-left: 5px;
		padding-right: 5px*/
	}

}

.min-h-86 {
	min-height: 86px
}

.height-42 {
	height: 42px
}

@media (max-width:767px) {
	.height-sm-auto {
		height: auto
	}

}

@media (max-width:767px) {
	.mmt-15 {
		margin-top: 15px !important
	}

}

@media (max-width:767px) {
	.mmb-15 {
		margin-bottom: 15px !important
	}

}

@media (max-width:435px) {
	.tf_mob_small {
		font-size: 11px !important;
		width: 80%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden
	}

}

.tf_border_left {
	border-left: solid 1px rgba(0, 120, 200, 0.2)
}

.tf_switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 30px
}

@media (max-width:1360px) {
	.tf_switch {
		width: 40px;
		height: 20px
	}

}

@media (max-width:767px) {
	.tf_switch {
		display: inline-block !important
	}

}

.tf_switch input {
	opacity: 0;
	width: 0;
	height: 0
}

.tf_switch .tf_slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.2);
	-webkit-transition: .4s;
	transition: .4s
}

.tf_switch .tf_slider:before {
	position: absolute;
	content: "";
	height: 23px;
	width: 23px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s
}

@media (max-width:1360px) {
	.tf_switch .tf_slider:before {
		height: 14px;
		width: 14px;
		bottom: 3px
	}

}

.tf_switch .tf_slider.tf_round {
	border-radius: 34px
}

.tf_switch .tf_slider.tf_round:before {
	border-radius: 50%
}

.tf_switch input:checked+.tf_slider {
	background-color: #0078c8
}

.tf_switch input:focus+.tf_slider {
	box-shadow: 0 0 1px #0078c8
}

.tf_switch input:checked+.tf_slider:before {
	-webkit-transform: translateX(29px);
	-ms-transform: translateX(29px);
	transform: translateX(29px)
}

@media (max-width:1360px) {
	.tf_switch input:checked+.tf_slider:before {
		-webkit-transform: translateX(18px);
		-ms-transform: translateX(18px);
		transform: translateX(18px)
	}

}

.tf_switch.tf_switch_sm {
	width: 40px;
	height: 20px;
	display: inline-block !important
}

.tf_switch.tf_switch_sm .tf_slider:before {
	height: 14px;
	width: 14px;
	bottom: 3px
}

.tf_switch.tf_switch_sm input:checked+.tf_slider:before {
	-webkit-transform: translateX(18px);
	-ms-transform: translateX(18px);
	transform: translateX(18px)
}

.input-group-text {
	padding: 5px !important;
	border-width: 0px;
	background-color: rgba(0, 120, 200, 0.2);
	color: #0078c8;
	min-width: 30px !important;
	text-align: center !important;
	justify-content: center;
	font-weight: 500;
	border-radius: 0px !important
}

.input-group .input-group-prepend {
	position: absolute;
	left: 2px;
	background-color: #fff;
	pointer-events: none;
	z-index: 9;
	top: 2px;
	bottom: 2px;
	border-radius: 3px 0px 0px 3px
}

.input-group .input-group-prepend ~ .form-control {
	padding-left: 2.3rem
}

.input-group>.form-control {
	border-radius: 3px !important
}

.input-group>.form-control ~ .input-group-prepend {
	right: 3px;
	left: initial !important;
	border-radius: 0px 3px 3px 0px
}

.input-group.tf_input_group_btns .input-group-prepend {
	position: initial !important;
	pointer-events: initial !important
}

.input-group.tf_input_group_btns .input-group-prepend .tf_btn {
	border-color: transparent;
	max-width: 50px !important;
	min-width: initial !important;
	border-radius: 0px !important
}

.input-group.tf_input_group_btns .form-control {
	text-align: center;
	font-size: 18px !important
}

.input-group.input-group-static .input-group-prepend {
	position: static;
	margin-right: 0px !important
}

.input-group.input-group-static .input-group-prepend ~ .form-control {
	padding-left: 10px !important
}

.modal-backdrop {
	z-index: 9999
}

.modal {
	z-index: 99999
}

.table th, .table td {
	line-height: 1.2
}

@media (min-width:1360px) and (max-width:1600px) {
	.table th, .table td {
		min-width: initial !important;
		padding: 0.4rem;
	}

}

@media (min-width:767px) and (max-width:1200px) {
	.table th, .table td {
		min-width: 90px
	}

}

.disabled-field .form-label {
	background-color: transparent
}

.sidebar-closed {
	overflow-x: hidden;
	overflow-y: auto
}

@media (max-width:667px) {
	.sidebar-closed {
		overflow: hidden
	}

}

.bg-primary {
	background: #0078c8 !important
}

.color-grey {
	color: rgba(0, 0, 0, 0.8) !important
}

.bg_light_gray {
	background: #f3f5fb !important
}

.bg-pink {
	background: #f52a65 !important
}

.bg-cyan {
	background: #52c2d0 !important
}

.tf_alt {
	color: #878c98
}

.text-primary {
	color: #0078c8 !important
}

.btn:hover, .btn:focus, .btn:active, .btn.active {
	box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.4), 0 4px 23px 0px rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.2)
}

.btn.btn-primary {
	border-radius: 3px;
	letter-spacing: -0.03em;
	color: white;
	background: #0078c8;
	border-color: transparent;
	transition: all 0.3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.btn.btn-primary {
		transition: none
	}

}

.btn.btn-primary:hover {
	background: #0078c8;
	opacity: 0.9
}

.btn.btn-thin {
	padding: 9px 26px;
	letter-spacing: 0.02em
}

.btn.btn-nocart::after {
	display: none
}

.btn.btn-primary:not(:disabled):not(.disabled):active, .btn.btn-primary:not(:disabled):not(.disabled).active {
	background-color: #0078c8;
	border-color: #0078c8
}

.show>.btn-primary.dropdown-toggle {
	background-color: #0078c8;
	border-color: #0078c8
}

.tf_btn {
	font-size: 14px;
	padding: 8px 15px;
	border-width: 2px;
	line-height: 1;
	font-weight: 500;
	min-width: 90px
}

@media (min-width:768px) and (max-width:1359px) {
	.tf_btn {
		padding: 5px 10px;
		min-width: 70px
	}

}

@media (max-width:667px) {
	.tf_btn {
		margin-bottom: 5px;
		margin-right: 3px
	}

}

.tf_btn.tf_btn_off {
	opacity: 0.3
}

.tf_btn.tf_btn_off:hover, .tf_btn.tf_btn_off:focus, .tf_btn.tf_btn_off:active, .tf_btn.tf_btn_off.active {
	opacity: 1
}

.tf_btn.btn-sm {
	padding: 5px 10px;
	min-width: 70px
}

.tf_btn.btn-sm i {
	font-size: 18px;
	vertical-align: middle;
	margin-right: 5px;
	margin-top: -3px
}

@media (min-width:1360px) and (max-width:1600px) {
	.tf_btn.btn-sm i {
		margin-top: 0px
	}

}

.tf_btn.btn-lg {
	padding: .5rem 2rem;
	font-size: 1.25rem;
	line-height: 1.5;
	border-radius: .3rem
}

.tf_btn.tf_ico {
	min-width: initial !important;
	font-size: 16px;
	padding: 7px 14px !important
}

@media (max-width:1360px) {
	.tf_btn.tf_ico {
		padding: 7.5px 13px
	}

}

.tf_btn.tf_btn_ico {
	padding: 0px !important;
	min-width: initial !important;
	width: 25px;
	height: 25px;
	font-size: 16px !important;
	border-radius: 50px !important
}

.ad-link-icon {
	color: #0078c8
}

.ad-link-icon i {
	font-size: 11px;
	display: inline-block;
	margin-left: 5px;
	vertical-align: top
}

.ad-link-icon:hover {
	color: black;
	text-decoration: none
}

.bootstrap-select {
	width: 100% !important;
	margin-bottom: 0px
}

.bootstrap-select.open>.btn-default.dropdown-toggle {
	color: #0078c8;
	background-color: white;
	border-color: rgba(0, 120, 200, 0.1);
	-webkit-box-shadow: none !important;
	box-shadow: none !important
}

.bootstrap-select select {
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	border: none;
	opacity: 0
}

.bootstrap-select .dropdown-toggle {
	padding-right: 25px !important
}

.bootstrap-select .dropdown-toggle .filter-option {
	vertical-align: middle;
	letter-spacing: 0.01em;
	line-height: 1;
	/* color: #0078c8; */
	font-weight: 400;
}

.bootstrap-select .dropdown-toggle .caret {
	right: 5px;
	border: none;
	width: 13px
}

.bootstrap-select .dropdown-toggle .caret:before {
	content: '\7e';
	font-family: "cashnet-ico";
	font-size: 18px;
	display: inline-block;
	position: absolute;
	top: 50%;
	-moz-transform: translateY(-40%);
	-ms-transform: translateY(-40%);
	-webkit-transform: translateY(-40%);
	transform: translateY(-40%);
	opacity: 0
}

.bootstrap-select .btn {
	width: 100%;
	text-align: left;
	position: relative;
	border: none;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	outline: none !important;
	border-radius: 0;
	padding: 9px 15px;
	font-size: 14px;
	font-weight: 500;
	background-color: white;
	color: #0078c8;
	font-weight: 500;
	border: solid 2px rgba(0, 120, 200, 0.2);
	line-height: 1;
	border-radius: 3px;
}

.bootstrap-select .btn:hover, .bootstrap-select .btn:focus, .bootstrap-select .btn:active {
	outline: none !important
}

.bootstrap-select .btn:after {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -3px;
	color: rgba(0, 120, 200, 0.5)
}

.bootstrap-select .dropdown-menu {
	width: 100%;
	border: solid 2px rgba(0, 120, 200, 0.1);
	border-radius: 0;
	background: white;
	top: 94%
}

@media (max-width:1024px) {
	.bootstrap-select .dropdown-menu {
		position: absolute;
		z-index: 11 !important
	}

}

.bootstrap-select .dropdown-menu.inner {
	position: static;
	display: block;
	border: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 0;
	background: none
}

.bootstrap-select .dropdown-menu.inner li:after {
	content: '';
	width: calc(100% - 24px);
	margin-left: 12px;
	display: block;
	height: 1px;
	background: #ebebeb
}

.bootstrap-select .dropdown-menu.inner li:last-child:after {
	display: none
}

.bootstrap-select .dropdown-menu.inner li a {
	display: block;
	color: rgba(0, 0, 0, 0.7);
	font-size: 14px;
	font-weight: 400;
	padding: 5px 12px
}

@media (max-width:320px) {
	.bootstrap-select .dropdown-menu.inner li a {
		font-size: 12px
	}

}

.bootstrap-select .dropdown-menu.inner li a:hover, .bootstrap-select .dropdown-menu.inner li a:focus, .bootstrap-select .dropdown-menu.inner li a:active {
	background: rgba(0, 120, 200, 0.1);
	color: #0078c8
}

.bootstrap-select .dropdown-menu.inner li.selected a {
	background: rgba(0, 120, 200, 0.1);
	outline: none
}

.bootstrap-select .dropdown-menu.inner li.selected:after {
	display: none
}

.bootstrap-select .dropdown-menu.open {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-top: solid 1px rgba(79, 83, 183, 0.1)
}

.ad-underline {
	text-decoration: underline
}

.alert {
	border: none;
	color: white;
	font-weight: 500;
	padding: 17px 20px
}

.alert-success {
	background-color: #197b30
}

.alert-danger {
	background-color: #bb0b10
}

.dropdown .dropdown-menu.dropdown-right {
	right: 0px !important;
	left: initial !important
}

table.dataTable thead>tr>th.sorting_asc, table.dataTable thead>tr>th.sorting_desc, table.dataTable thead>tr>th.sorting, table.dataTable thead>tr>td.sorting_asc, table.dataTable thead>tr>td.sorting_desc, table.dataTable thead>tr>td.sorting {
	padding-right: 15px !important
}

table.dataTable thead .sorting::before, table.dataTable thead .sorting::after, table.dataTable thead .sorting_asc::before, table.dataTable thead .sorting_asc::after, table.dataTable thead .sorting_desc::before, table.dataTable thead .sorting_desc::after, table.dataTable thead .sorting_asc_disabled::before, table.dataTable thead .sorting_asc_disabled::after, table.dataTable thead .sorting_desc_disabled::before, table.dataTable thead .sorting_desc_disabled::after {
	opacity: 0
}

table.dataTable thead .sorting_asc::before, table.dataTable thead .sorting_desc::after {
	opacity: 1
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:before {

	/*right: 0px !important;
	content: '2fc' !important;
	font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
	color: #0078c8;
	font-size: 18px;
	line-height: 1;
	height: 100%;
	bottom: 0px !important;
	top: 0px !important;
	display: -webkit-flex;
	display: flex;
	justify-content: center;
	align-items: center*/
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {

	/*right: 0px !important;
	content: '2f9' !important;
	font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
	color: #0078c8;
	font-size: 18px;
	line-height: 1;
	height: 100%;
	bottom: 0px !important;
	top: 0px !important;
	display: -webkit-flex;
	display: flex;
	justify-content: center;
	align-items: center*/
}

@media (max-width:767px) {
	table.dataTable>tbody>tr.child ul.dtr-details {
		width: 100%
	}

}

table.dataTable {
	/* margin-top: 10px !important;
	margin-bottom: 10px !important */
}
table.dataTable thead th, table.dataTable thead td {
	border-bottom: 1px solid #ddd;
}

table.dataTable.m-0 {
	margin: 0px !important
}

table.fixedHeader-floating {
	z-index: 9999999999;
	top: 55px !important
}

.dataTables_scrollHeadInner, .dataTables_scrollFootInner {
	width: 100% !important
}

.dataTables_scrollHeadInner table, .dataTables_scrollHeadInner table, .dataTables_scrollFootInner table, .dataTables_scrollFootInner table {
	width: 100% !important
}

.tf_card {
	box-shadow: 0px 10px 20px 0px rgba(46, 91, 255, 0.07);
	border: 0px;
	margin-bottom: 30px;
	height: calc(100% - 30px)
}

@media (max-width:1360px) {
	.tf_card .card-title {
		font-size: 18px
	}

}

.sidebar-closed .tooltip {
	display: block !important
}

.tooltip {
	z-index: 999999999 !important
}

.tooltip>.tooltip-inner {
	background-color: rgba(0, 0, 0, 0.9);
	font-family: "Fira Sans";
	font-weight: 500;
	font-size: 12px;
	position: relative
}

@media (max-width:1360px) {
	.tooltip>.tooltip-inner {
		font-size: 11px
	}

}

.tooltip>.tooltip-inner::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	border-radius: 5px
}

.mCSB_container {
	margin-right: 18px !important
}

.mCSB_container.mCS_no_scrollbar_y {
	margin: 0px !important
}

.mCSB_scrollTools.mCS-dark .mCSB_dragger .mCSB_dragger_bar {
	background-color: #878c98
}

.mCSB_scrollTools.mCS-dark .mCSB_dragger:hover .mCSB_dragger_bar {
	background-color: #0078c8
}

.datepicker-container {
	z-index: 99999 !important
}

.form-group label {
	line-height: 1
}

.form-group .bootstrap-select .dropdown-toggle {
	border: solid 2px rgba(0, 120, 200, 0.2);
	padding: 8px 15px
}

.form-group .bootstrap-select .dropdown-toggle .filter-option {
	font-weight: 400;
	color: rgba(0, 0, 0, 0.8)
}

.form-group.tf_form_check {
	margin-bottom: 1rem !important
}

.form-group.tf_form_check.mb-0 {
	margin-bottom: 0px !important
}

.tf_form_check {
	padding-left: 25px;
	line-height: 1.5;
	min-height: 20px;
	margin: 5px 8px 5px 0px
}

@media (max-width:320px) {
	.tf_form_check {
		margin: 5px 0px 0px 0px
	}

}

.tf_form_check input {
	position: absolute !important;
	width: 100%;
	left: 0;
	top: 0;
	margin: 0px;
	opacity: 0;
	cursor: pointer;
	height: 100%;
	z-index: 99
}

.tf_form_check .form-check-label::before {
	content: '';
	position: absolute;
	width: 20px;
	height: 20px;
	border: solid 2px rgba(0, 120, 200, 0.2);
	left: 0;
	top: 0;
	border-radius: 3px
}

.tf_form_check .form-check-label::after {
	content: '\60';
	font-family: "cashnet-ico";
	position: absolute;
	left: 0;
	top: 0;
	font-size: 12px;
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 20px;
	background-color: #0078c8;
	opacity: 0;
	border-radius: 3px;
	color: #fff;
	box-shadow: 0px 6px 16px -2px rgba(0, 120, 200, 0.6);
	transition: all 0.3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.tf_form_check .form-check-label::after {
		transition: none
	}

}

.tf_form_check .form-check-label:empty {
	width: auto !important
}

.tf_form_check input:checked ~ .form-check-label::after {
	opacity: 1;
	transition: all 0.3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.tf_form_check input:checked ~ .form-check-label::after {
		transition: none
	}

}

.tf_form_check.text-white .form-check-label::before {
	border-color: rgba(255, 255, 255, 0.4)
}

.tf_form_check.text-white .form-check-label::after {
	background-color: #fff !important
}

.tf_form_radio {
	padding-left: 25px;
	line-height: 1.5;
	min-height: 20px;
	margin: 5px 8px 5px 0px
}

.tf_form_radio input {
	position: absolute !important;
	width: 100%;
	left: 0;
	top: 0;
	margin: 0px !important;
	opacity: 0;
	cursor: pointer;
	height: 100%;
	z-index: 99
}

.tf_form_radio .form-check-label::before {
	content: '';
	position: absolute;
	width: 20px;
	height: 20px;
	border: solid 2px rgba(0, 120, 200, 0.2);
	left: 0;
	top: 0;
	border-radius: 50px
}

.tf_form_radio .form-check-label::after {
	content: '';
	position: absolute;
	left: 5px;
	top: 5px;
	font-size: 14px;
	width: 10px;
	height: 10px;
	text-align: center;
	line-height: 23px;
	background-color: #0078c8;
	border-radius: 50px;
	box-shadow: 0px 6px 16px -2px rgba(0, 120, 200, 0.6);
	opacity: 0;
	transition: all 0.3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.tf_form_radio .form-check-label::after {
		transition: none
	}

}

.tf_form_radio input:checked ~ .form-check-label::after {
	opacity: 1;
	transition: all 0.3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.tf_form_radio input:checked ~ .form-check-label::after {
		transition: none
	}

}

.tf_form_radio.text-white .form-check-label::before {
	border-color: rgba(255, 255, 255, 0.4)
}

.tf_form_radio.text-white .form-check-label::after {
	background-color: #fff !important
}

.dataTables_wrapper .row:nth-child(2) {
	margin: 0px
}

.dataTables_wrapper .row:nth-child(2) [class*="col"] {
	padding: 0px;
	overflow: auto;
}

@media (max-width:767px) {
	.dataTables_wrapper .row:nth-child(2) [class*="col"] {
		overflow: auto
	}

}

@media (max-width:1366px) {
	.dataTables_wrapper .btn-sm, .dataTables_wrapper .btn-group-sm>.btn {
		font-size: 11px
	}

	.dataTables_wrapper td, .dataTables_wrapper th {
		line-height: 1.6
	}

}

@media (max-width:767px) {
	.dataTables_wrapper {
		overflow: auto;
		width: 100%
	}

	.dataTables_wrapper td, .dataTables_wrapper th {
		line-height: 1.5
	}

}
.dataTables_wrapper.no-footer .dataTables_scrollBody {
	border-bottom: 1px solid #ddd !important;
}
table.dataTable.no-footer {
	/* border-bottom: 1px solid #ddd; */
}

.tf_table_wrapper .dataTables_wrapper .dataTables_length label .bootstrap-select {
	width: auto !important;
	height: auto !important;
	margin: 0px 5px;
}

.tf_table_wrapper .dataTables_wrapper .dataTables_length label .bootstrap-select .dropdown-toggle {
	border-color: rgba(0, 120, 200, 0.2);
	padding: 10px 15px !important;
	margin: 0px 5px;
	width: auto !important;
}

.tf_table_wrapper .dataTables_wrapper .dataTables_filter .form-control {
	margin-bottom: 0px
}

.tf_table_wrapper .dataTables_wrapper .row:nth-child(1) {
	align-items: center
}

@media (max-width:1200px) {
	.tf_table_wrapper .dataTables_wrapper .row:nth-child(2) {
		overflow: auto
	}

}

.tf_table_wrapper .dataTables_wrapper .page-item.active .page-link {
	background-color: #0078c8;
	border-color: #0078c8
}

.tf_table_wrapper .dataTables_wrapper .table th, .tf_table_wrapper .dataTables_wrapper .table td {
	line-height: 1.2
}
.tf_table_wrapper .dataTables_wrapper .table th {
	font-size: 13px;
	font-weight: 500;
	min-width: 60px !important;
	padding-left: 10px !important;
}
.tf_table_wrapper .dataTables_wrapper .table td.center {
	text-align: center;
}
.sticky-top {
	position: sticky !important
}

@media (max-width:767px) {
	.sticky-top {
		position: inherit !important;
		z-index: inherit
	}

}

.t-1 {
	top: 1rem
}

.t-2 {
	top: 2rem
}

.t-3 {
	top: 3rem
}

.t-4 {
	top: 4rem
}

.t-5 {
	top: 5rem
}

.l-1 {
	left: 1rem !important
}

.l-2 {
	left: 2rem !important
}

.l-3 {
	left: 3rem !important
}

.l-4 {
	left: 4rem !important
}

.l-5 {
	left: 5rem !important
}

.breadcrumb {
	background-color: transparent;
	padding: 0px
}

.tf_user_img {
	border: solid 1px rgba(0, 120, 200, 0.2);
	display: inline-block
}

.tf_user_img.border-none {
	border: 0px
}

.tf_user_img.tf_user_img_sm {
	min-width: 35px;
	width: 35px;
	height: 35px
}

.tf_user_img.tf_user_img_md {
	width: 50px;
	height: 50px
}

@media (max-width:1366px) {
	.tf_user_img.tf_user_img_md {
		width: 40px;
		height: 40px
	}

}

.tf_user_img.tf_user_img_lg {
	width: 65px;
	height: 65px;
	display: inline-block
}

.tf_user_img.tf_user_img_100x100 {
	width: 100px;
	height: 100px
}

.tf_user_img.tf_user_img_200x200 {
	width: 200px;
	height: 200px
}

.tf_user_img.tf_img_circle {
	border-radius: 100%;
	overflow: hidden
}

.tf_user_img img {
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%
}

.bg-primary {
	background-color: #20418c !important;
}

.tf_form_radio {
	padding-left: 25px;
	line-height: 1.5;
	min-height: 20px;
	margin: 5px 8px 5px 0px
}

.tf_form_radio input {
	position: absolute !important;
	width: 100%;
	left: 0;
	top: 0;
	margin: 0px !important;
	opacity: 0;
	cursor: pointer;
	height: 100%;
	z-index: 99
}

.tf_form_radio .form-check-label::before {
	content: '';
	position: absolute;
	width: 20px;
	height: 20px;
	border: solid 2px rgba(0, 120, 200, 0.2);
	left: 0;
	top: 0;
	border-radius: 50px
}

.tf_form_radio .form-check-label::after {
	content: '';
	position: absolute;
	left: 5px;
	top: 5px;
	font-size: 14px;
	width: 10px;
	height: 10px;
	text-align: center;
	line-height: 23px;
	background-color: rgb(32, 65, 140);
	border-radius: 50px;
	box-shadow: 0px 6px 16px -2px rgba(0, 120, 200, 0.6);
	opacity: 0;
	transition: all 0.3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.tf_form_radio .form-check-label::after {
		transition: none
	}

}

.tf_form_radio input:checked ~ .form-check-label::after {
	opacity: 1;
	transition: all 0.3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.tf_form_radio input:checked ~ .form-check-label::after {
		transition: none
	}

}

.tf_form_radio.text-white .form-check-label::before {
	border-color: rgba(255, 255, 255, 0.4)
}

.tf_form_radio.text-white .form-check-label::after {
	background-color: #fff !important
}

.tf_btn {
	font-size: 14px;
	padding: 10px 15px;
	border-width: 2px;
	line-height: 1;
	font-weight: 500;
	min-width: 90px;
}

.tf_btn.btn-primary {
	background-color: rgb(32, 65, 140);
	border-color: transparent;
}

.tf_btn:hover {
	opacity: 0.9;
}

/*----- navbar menu ------*/
.navbar-subnav {
	background: rgb(39, 81, 152);
	background: -moz-linear-gradient(90deg, rgba(39, 81, 152, 1) 0%, rgba(32, 65, 140, 1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(39, 81, 152, 1) 0%, rgba(32, 65, 140, 1) 100%);
	background: linear-gradient(90deg, rgba(39, 81, 152, 1) 0%, rgba(32, 65, 140, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#275198", endColorstr="#20418c", GradientType=1);
	box-shadow: 0px 10px 30px -10px rgba(5, 33, 81, 0.3);
	margin-bottom: 30px;
}

.navbar-subnav .nav-item .nav-link i {
	font-size: 16px !important;
	vertical-align: middle;
	width: 18px;
	margin-top: -2px;
}

.navbar-subnav .nav-item {
	margin-right: 10px;
}

.navbar-subnav .nav-item .nav-link {
	line-height: 1;
}

.navbar-subnav .nav-item.active .nav-link {
	font-weight: 500;
}

.navbar-subnav .nav-item.dropdown .dropdown-menu {
	margin-top: 8px;
	border: 0px;
	border-radius: 0px 0px 10px 10px;
	box-shadow: 0px 20px 30px -10px rgba(32, 65, 140, 0.1);
}

.navbar-subnav .nav-item.dropdown .dropdown-menu::before {
	content: '';
	width: 0px;
	height: 0px;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 7px solid rgb(255, 255, 255);
	position: absolute;
	bottom: 100%;
	left: 10px;
}

.navbar-subnav .nav-item.dropdown .dropdown-menu .dropdown-item {
	font-size: 12px;
	font-weight: 400;
}

.dropdown-item:focus, .dropdown-item:hover {
	color: rgb(255, 255, 255);
	text-decoration: none;
	background-color: rgb(211, 45, 38);
}

/*=====  End of commoan css for class  ======*/

/*===========================================
=            login page css here            =
===========================================*/

.tf_login_page_wrapper body {
}

.tf_login_page_wrapper .tf_login_bg {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d1d4dd", endColorstr="#ffffff", GradientType=1);
	background: rgb(209, 212, 221);
	background: -moz-linear-gradient(0deg, rgba(209, 212, 221, 1) 0%, rgba(255, 255, 255, 1) 65%);
	background: -webkit-linear-gradient(0deg, rgba(209, 212, 221, 1) 0%, rgba(255, 255, 255, 1) 65%);
	background: linear-gradient(0deg, rgba(209, 212, 221, 1) 0%, rgba(255, 255, 255, 1) 65%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d1d4dd", endColorstr="#ffffff", GradientType=1);
}

.tf_login_page_wrapper .tf_login_bg span {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-size: contain;
	background-position: bottom;
	background-blend-mode: luminosity;
	opacity: 0.3;
	filter: blur(3px);
}

.tf_login_page_wrapper .tf_login_wrapper .tf_login_head {
	max-width: 250px;
	margin: 0px auto;
}

.tf_login_page_wrapper .card {
	border: 0px;
	border-radius: 10px;
	box-shadow: 0px 10px 30px -10px rgba(32, 65, 140, 0.47);
}

.tf_login_page_wrapper .card .card-header {
	background-color: transparent;
	border: 0px;
	position: relative;
	padding: 15px 30px;
}

.tf_login_page_wrapper .card .card-header h4 {
	margin-bottom: 5px;
	font-weight: 900;
}

.tf_login_page_wrapper .card .card-header p {
	font-size: 14px;
	color: rgba(0, 0, 0, 0.64);
}

.tf_login_page_wrapper .card .card-body {
	padding: 30px 30px;
}

.tf_login_page_wrapper .card .input-group .form-control {
	font-size: 14px;
	border-radius: 50px;
	border-width: 2px;
	box-shadow: none !important;
	padding: 10px 15px;
	padding-left: 40px;
	height: 40px;
	line-height: 1;
	background-color: transparent !important;
}

.tf_login_page_wrapper .card .input-group .input-group-prepend {
	position: absolute;
	z-index: 2;
	top: 2px;
	bottom: 2px;
	left: 2px;
}

.tf_login_page_wrapper .card .input-group .input-group-prepend .input-group-text {
	border: 0px;
	background-color: transparent;
	font-size: 22px;
	color: rgba(32, 65, 140, 0.5);
}

/*=====  End of login page css here  ======*/
.tf_section_wrapper.tf_widgets_EmployeeAttendance .tf_card {
	background-color: #fff;
	padding: 30px;
	position: relative;
	top: 0px;
	overflow: hidden;
	transition: all 0.3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.tf_section_wrapper.tf_widgets_EmployeeAttendance .tf_card {
		transition: none
	}

}

.tf_section_wrapper.tf_widgets_EmployeeAttendance .tf_card .tf_card_head .tf_count {
	color: #25282c;
	font-size: 26px;
	margin: 0px;
	line-height: 1;
	font-weight: 600
}

.tf_section_wrapper.tf_widgets_EmployeeAttendance .tf_card .tf_card_head .tf_title {
	font-size: 14px;
	font-weight: 500;
	color: #495057;
	margin: 0px
}

.tf_section_wrapper.tf_widgets_EmployeeAttendance .tf_card .tf_card_ico {
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	border-radius: 50px;
	font-size: 24px;
	transition: all 0.3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.tf_section_wrapper.tf_widgets_EmployeeAttendance .tf_card .tf_card_ico {
		transition: none
	}

}

.tf_section_wrapper.tf_widgets_EmployeeAttendance .tf_card .tf_card_ico.bg-primary {
	background-color: rgba(0, 120, 200, 0.2) !important;
	color: #0078c8
}

.tf_section_wrapper.tf_widgets_EmployeeAttendance .tf_card .tf_card_ico.bg-warning {
	background-color: rgba(248, 162, 0, 0.2) !important;
	color: #f8a200
}

.tf_section_wrapper.tf_widgets_EmployeeAttendance .tf_card .tf_card_ico.bg-pink {
	background-color: rgba(245, 42, 101, 0.2) !important;
	color: #f52a65
}

.tf_section_wrapper.tf_widgets_EmployeeAttendance .tf_card .tf_card_ico.bg-cyan {
	background-color: rgba(82, 194, 208, 0.2) !important;
	color: #52c2d0
}

.tf_section_wrapper.tf_widgets_EmployeeAttendance .tf_card .tf_card_ico.bg-success {
	background-color: rgba(40, 167, 69, 0.2) !important;
	color: rgb(40, 167, 69) !important;
}

.tf_section_wrapper.tf_widgets_EmployeeAttendance .tf_card:hover {
	box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.17);
	top: -5px;
	transition: all 0.3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.tf_section_wrapper.tf_widgets_EmployeeAttendance .tf_card:hover {
		transition: none
	}

}

.tf_section_wrapper.tf_widgets_EmployeeAttendance .tf_card:hover .tf_card_ico {
	transform: scale(1.2);
	transition: all 0.3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.tf_section_wrapper.tf_widgets_EmployeeAttendance .tf_card:hover .tf_card_ico {
		transition: none
	}

}

.main {
	height: calc(100% - 205px);
	min-height: auto;
}

footer {
	text-align: center;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	padding-top: 30px;
	margin-top: 30px;
	position: sticky;
	top: 100%;
	display: inline-block;
	padding: 15px;
	width: 100%;
}

@media (max-width:667px) {
	footer .float-left, footer .float-right {
		float: none !important
	}

}



.dataTables_wrapper .dataTables_paginate .paginate_button {
	padding: 0px;
	border-radius: 2px;
	float: left;
	border: 0px !important;
	margin: 0px;
}
.dataTables_wrapper.form-inline {
	display: block !important;
}	

.tf_btn.tf_ico_btn {
	min-width: auto !important;
	padding: 0px !important;
	width: 30px;
	height: 30px;
	border-radius: 50px;
	border: 0px !important;
	font-size: 16px;
}


.tf_datepicker .controls, .tf_datepicker .control-group {
	position: relative;
}
.tf_datepicker .controls img.ui-datepicker-trigger, .tf_datepicker .control-group img.ui-datepicker-trigger {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	height: 35px;
	padding: 10px;
	cursor: pointer;
}

label {
	line-height: 1;
}

.dataTables_length, .dataTables_filter {
	margin-bottom: 10px !important;
}
.dataTables_paginate {
	margin-top: 10px !important;
}

.tf_table_overflow tbody td {
	vertical-align: middle;
}
.tf_table_overflow .btn {
	white-space: nowrap;
}

.ui-menu .ui-menu-item a {
	white-space: nowrap;
	font-size: 13px;
	font-family: 'Fira Sans', sans-serif;
	font-weight: 500 !important;
}

.custom-file-label {
	line-height: 1.5em !important;
}
.form-group .custom-file-input ~ .custom-file-label::after {
	content: attr(data-label) !important;
	height: auto !important;
	border-left: 0px;
	border-radius: 0px;
	font-weight: 400;
	line-height: inherit;
	border-left: solid 8px #fff;
}


.jconfirm .jconfirm-scrollpane {
	perspective-origin: unset !important;
	perspective: none !important;
	width: 100% !important;
	height: 100% !important;
}

.tf_download_file {
	margin-bottom: 1rem;
}
.tf_download_file #existingFileURL {
	height: 35px;
	width: 100%;
	border: solid 2px rgba(0, 120, 200, 0.2);
	padding: 5px 10px;
	color: rgba(0, 0, 0, 0.8);
	font-weight: 400;
	font-size: 14px;
	transition: all 0.3s ease-in-out;
	border-radius: 3px;
	position: relative;
	padding-left: 30px;
	line-height: 1.5;
}
.tf_download_file #existingFileURL i {
	position: absolute;
	left: 0;
	font-size: 18px;
	width: 30px;
	text-align: center;
	height: 100%;
	line-height: 30px;
	top: 0;
	color: rgba(0, 123, 255, 0.47);
}
.cp {cursor: pointer;}
.mr-10 {margin-right: 10px;}
#noti-modal {width: 100%;}
.help-block.error {
	color: #a94442;
}
.disabled-row {
	background-color: grey!important;
	color: #fff;
}
.react-date-picker__button{
	padding: 0 !important;
}
.react-date-picker__wrapper {
	border: none !important;
}
.date-input {
	height: 35px;
    width: 100%;
    border: solid 2px rgba(0, 120, 200, 0.2);
    padding: 5px 10px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
}